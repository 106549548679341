<template>
  <VueContentHolder id="divTurnoverWrapper">
    <BrandResultView
      @handleSubmit="submitResultPopUp"
      @close="closeModal"
      :key="showResultView"
      :showComponent="showResultView"
      :contentWithGraph="true"
    >
      <VueSpacer level="50" />
      <div class="result-doughnut-chart-holder">
        <div class="result-doughnut-chart-wrapper">
          <div class="doughnut-headline">
            <VueText color="grey-40" sizeLevel="6"
              >Kategorilere Göre <br />
              Ciro Dağılımı</VueText
            >
          </div>
          <div class="doughnut-wrapper">
            <BrandDoughnutChart
              :key="renderKey"
              v-if="chartLoaded"
              :chartData="doughnutDataChart"
              :chartOptions="resultDoughnutChartOptions"
              class="doughnut-chart"
            />
          </div>
        </div>
        <div class="result-doughnut-chart-footer">
          <div
            class="result-footer-single-row"
            v-for="(item, index) in doughnutDataChart.labels"
            :key="item"
          >
            <div
              class="label-color"
              :style="{ backgroundColor: doughnutDataChart.datasets[0].backgroundColor[index] }"
            ></div>
            <VueText color="grey-40" sizeLevel="3" class="single-footer-text">{{ item }}</VueText>
          </div>
        </div>
      </div>
    </BrandResultView>
    <HeaderWithChart :text="headerContent[0].text" :headline="headerContent[0].headline">
      <!-- <div class="header-with-chart-wrapper">
        <div class="chart-body-wrapper">
          <div class="doughnut-headline" @click="handleModalState('open')">
            <VueText color="grey-40" sizeLevel="6"
              >Kategorilere Göre<br />
              Ciro Dağılımı Yüzdesel</VueText
            >
            <VueIcon
              :width="icons.info.width"
              :height="icons.info.height"
              :iconName="icons.info.name"
              iconColor="#23303d"
            ></VueIcon>
          </div>
          <div class="doughnut-wrapper">
            <BrandDoughnutChart
              :key="renderKey"
              v-if="chartLoaded"
              :data="doughnutCategoryChart"
              :options="doughnutChartOptions"
              class="doughnut-chart"
            ></BrandDoughnutChart>
            <VueText class="mock-text" color="grey-40" weightLevel="1" sizeLevel="14">{{
              getCategoryPercentage
            }}</VueText>
          </div>
        </div>
        <div class="doughnut-chart-footer">
          <div
            class="footer-single-row"
            v-for="(item, index) in doughnutCategoryChart.labels"
            :key="item"
          >
            <div
              class="label-color"
              :style="{
                backgroundColor:
                  doughnutCategoryChart.datasets[0].backgroundColor[index] || '#79838e',
              }"
            ></div>
            <VueText color="grey-40" sizeLevel="3" class="single-footer-text">{{ item }}</VueText>
          </div>
        </div>
      </div> -->
    </HeaderWithChart>

    <VueSpacer level="20" />
    <VueForm :bodyPadding="['0', '20']" ref="form">
      <div v-for="(item, index) in formModel" :key="index">
        <VueInput
          class="form-input"
          :name="item.name"
          :id="item.name"
          type="text"
          :label="item.label"
          v-model="item.amount"
          :messageOnTop="true"
          validationRules="numeric"
          validatorName="Ciro dağılımı"
          style="padding-bottom: 20px;"
        />
      </div>
    </VueForm>
    <VueSpacer level="60" />
    <BrandButton
      v-if="!showResultView"
      :size="sizes.xxLarge"
      :contentAlignment="constants.flexAlignment.center"
      type="submit"
      :padding="{ vertical: 15, horizontal: 0 }"
      @click="handleSubmit"
      :disabled="isDisabled"
      >HESAPLA</BrandButton
    >
    <BrandSelectModal
      :showModal="openInfoModal"
      :title="'Kategorilere Göre Ciro Dağılımı'"
      :plainTextModal="true"
      @click="handleModalState('close')"
      @onModalClose="handleModalState('close')"
      class="modal"
    >
      <VueContentHolder :padding="[20, 20, 80, 20]">
        <VueText sizeLevel="4" color="grey-80" weightLevel="3">{{ modalText }}</VueText>
      </VueContentHolder>
    </BrandSelectModal>
  </VueContentHolder>
</template>

<script>
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import BrandSelectModal from '@/components/brand/Modals/BrandModal/BrandSelectModal.vue';
import HeaderWithChart from '@/components/brand/Headers/HeaderWithChart.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import BrandDoughnutChart from '@/components/brand/BrandChart/BrandDoughnutChart.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandResultView from '@/components/brand/BrandResultView/BrandResultView.vue';
// import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import { Learn } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'Turnover',
  mixins: [gtmUtils],
  components: {
    HeaderWithChart,
    VueForm,
    VueInput,
    // VueIcon,
    BrandButton,
    VueContentHolder,
    VueSpacer,
    BrandDoughnutChart,
    BrandSelectModal,
    VueText,
    BrandResultView,
  },
  data() {
    return {
      chartDataToRender: [],
      renderKey: 0,
      chartLoaded: true,
      modalText: '',
      openInfoModal: false,
      showResultView: false,
      isDisabled: true,
      formModel: {
        philipMorrisProducts: {
          name: 'philipMorrisProducts',
          label: 'Philip Morris Ürünleri',
          amount: '',
        },
        stapleFood: { name: 'stapleFood', label: 'Temel Gıda Ürünleri', amount: '' },
        nutsAndSnacks: { name: 'nutsAndSnacks', label: 'Kuruyemiş ve Çerez', amount: '' },
        alcoholicBeverages: { name: 'alcoholicBeverages', label: 'Alkollü İçeçekler', amount: '' },
        nonAlcoholicBeverages: {
          name: 'nonAlcoholicBeverages',
          label: 'Alkolsüz İçeçekler',
          amount: '',
        },
        cleaningProducts: { name: 'cleaningProducts', label: 'Temizlik Ürünleri', amount: '' },
        productsFromOtherCompanies: {
          name: 'productsFromOtherCompanies',
          label: 'Diğer Sigara Firma Ürünleri',
          amount: '',
        },
        newspaperAndMagazine: {
          name: 'newspaperAndMagazine',
          label: 'Gazete ve Dergi',
          amount: '',
        },
        biscuitAndChocolates: {
          name: 'biscuitAndChocolates',
          label: 'Bisküvi ve Çikolatalar',
          amount: '',
        },
        others: { name: 'others', label: 'Diğer' },
      },
      headerContent: [
        {
          headline: 'Ciro dağılımınızı öğrenin',
          text: 'Aşağıdaki alanlara her bir kategori için günlük ortalama cironuzu giriniz. (TL)',
        },
      ],

      doughnutDataChart: {
        labels: [],
        datasets: [
          {
            label: 'Ciro Dağılımı',
            backgroundColor: [
              '#50d2c2',
              '#e5472d',
              '#b80342',
              '#f58011',
              '#e74a2c',
              '#00c3ff',
              '#1c638d',
              '#d3650e',
              '#579653',
              '#7c38bc',
            ],
            data: [],
          },
        ],
      },
      doughnutCategoryChart: {
        labels: [],
        datasets: [
          {
            label: 'Ciro Dağılımı',
            backgroundColor: ['#50d2c2', '#e5472d', '#b80342', '#f58011'],
            data: [],
          },
        ],
      },
      doughnutChartOptions: {
        rotation: Math.PI * 0.751,
        circumference: (Math.PI * 3) / 2,
        cutoutPercentage: 90,
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            padding: 20,
          },
        },
        tooltips: {
          mode: 'label',
          backgroundColor: '#ffffff',
          titleFontColor: '#23303d',
          bodyFontColor: '#23303d',
          titleFontStyle: 'normal',
          bodyFontStyle: 'bold',
        },
      },
      resultDoughnutChartOptions: {
        cutout: '60%',
        plugins: {
          datalabels: {
            color: 'gray',
            formatter: value => {
              if (!value) {
                return '';
              }
              return `${value} %`;
            },
            font: {
              size: 10,
            },
          },
          legend: {
            display: false,
          },
        },
        tooltips: {
          mode: 'label',
          backgroundColor: '#ffffff',
          titleFontColor: '#23303d',
          bodyFontColor: '#23303d',
          titleFontStyle: 'normal',
          bodyFontStyle: 'bold',
        },
      },
    };
  },
  computed: {
    getCategoryPercentage() {
      let sum = this.doughnutCategoryChart.datasets[0].data.reduce((total, num) => {
        return total + num;
      }, 0);
      return `%${sum}`;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    icons() {
      return ICON_VARIABLES;
    },
  },
  created() {
    this.setBottomBarStatus(false);
  },
  beforeCreate() {
    Learn.getRevenueDistribution().then(res => {
      let response = res.data.Data;
      this.modalText = response.title;
      let revenues = response.revenues;

      if (response && revenues && revenues.length > 0) {
        for (let category of revenues) {
          this.doughnutCategoryChart.datasets[0].data.push(category.value);
          this.doughnutCategoryChart.labels.push(category.title);
        }
        this.renderKey += 1;
      }
    });
  },
  mounted() {
    this.fetchLabelData().then(() => {
      if (this.doughnutDataChart.labels.length || this.doughnutCategoryChart.labels.length) {
        this.renderKey += 1;
      }
    });
  },
  destroyed() {
    this.setBottomBarStatus(true);
  },

  methods: {
    async calculateResultChartData() {
      this.doughnutDataChart.datasets[0].data = [];
      this.doughnutDataChart.labels = [];

      let total = 0;
      Object.values(this.formModel).forEach(item => {
        total += item.amount ? Number(item.amount) : 0;
      });

      Object.values(this.formModel).forEach(item => {
        const percentage = item.amount ? ((Number(item.amount) / total) * 100).toFixed(0) : 0;
        this.doughnutDataChart.datasets[0].data.push(percentage);
        this.doughnutDataChart.labels.push(item.label);
      });

      try {
        let pushData = {
          event: 'product_revenue_calculation',
          philip_morris: `%${(
            (100 * Number(this.formModel.philipMorrisProducts.amount)) /
            total
          ).toFixed(2)}`,
          temel_gida: `%${((100 * Number(this.formModel.stapleFood.amount)) / total).toFixed(2)}`,
          kuruyemis_cerez: `%${(
            (100 * Number(this.formModel.nutsAndSnacks.amount)) /
            total
          ).toFixed(2)}`,
          alkollu_icecekler: `%${(
            (100 * Number(this.formModel.alcoholicBeverages.amount)) /
            total
          ).toFixed(2)}`,
          temizlik_malzemeleri: `%${(
            (100 * Number(this.formModel.cleaningProducts.amount)) /
            total
          ).toFixed(2)}`,
          diger_sigara_firma_urunleri: `%${(
            (100 * Number(this.formModel.productsFromOtherCompanies.amount)) /
            total
          ).toFixed(2)}`,
          biskuvi_cikolata: `%${(
            (100 * Number(this.formModel.biscuitAndChocolates.amount)) /
            total
          ).toFixed(2)}`,
          alkolsuz_icecek: `%${(
            (100 * Number(this.formModel.nonAlcoholicBeverages.amount)) /
            total
          ).toFixed(2)}`,
          gazete_dergi: `%${(
            (100 * Number(this.formModel.newspaperAndMagazine.amount)) /
            total
          ).toFixed(2)}`,
          diger: `%${((100 * Number(this.formModel.others.amount)) / total).toFixed(2)}`,
        };
        this.pushDataLayerEvent('learn', pushData);
      } catch (err) {
        console.error(err, 'Turnover - Google analytics, iProspect error');
      }
    },
    async fetchLabelData() {
      const elements = document.getElementsByClassName('form-input');
      for (let element of elements) {
        this.doughnutDataChart.labels.push(element.innerText);
      }
    },
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    async handleSubmit() {
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        this.calculateResultChartData().then(() => {
          this.showResultView = true;

          if (this.showResultView) {
            document.documentElement.style.overflow = 'hidden';
          }
        });
      }
    },
    closeModal() {
      this.showResultView = false;
      this.doughnutDataChart.datasets[0].data.splice(
        0,
        this.doughnutDataChart.datasets[0].data.length,
      );
      if (!this.showResultView) {
        document.documentElement.style.overflow = 'auto';
      }
    },
    submitResultPopUp() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.ManageMyBusiness.path}`);
    },
    handleModalState(action) {
      if (action === 'open') {
        this.openInfoModal = true;
      }
      if (action === 'close') {
        this.openInfoModal = false;
      }
    },
  },
  watch: {
    formModel: {
      deep: true,
      handler() {
        let count = 0;
        Object.keys(this.formModel).forEach(key => {
          if (this.formModel[key].amount && this.formModel[key].amount.length > 0) {
            count++;
          }
          this.isDisabled = count === 0;
        });
      },
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  line-height: 1.86;
}

.header-with-chart-wrapper,
.doughnut-chart-holder,
.result-doughnut-chart-holder {
  border: 1px solid palette-color-level('grey', '20');
}

.header-with-chart-wrapper {
  .chart-body-wrapper {
    padding: palette-space-level('15');
  }
}

.result-doughnut-chart-wrapper {
  padding: palette-space-level('15');
}

.result-doughnut-chart-wrapper {
  background-color: palette-color-level('white', '100');
}
.doughnut-headline {
  display: flex;
  justify-content: space-between;
  margin-bottom: palette-space-level('20');
}
.doughnut-wrapper {
  max-width: 60%;
  margin: auto;
  position: relative;
}

.mock-text {
  position: absolute;
  left: 45%;
  top: calc(50% + 10px);
  transform: translate(-50%, -50%);
}

.result-footer-single-row,
.footer-single-row {
  display: flex;
  align-items: center;
}

.result-doughnut-chart-footer {
  flex-direction: column;
  justify-content: center;
  padding: palette-space-level('10');
  .result-footer-single-row {
    padding: palette-space-level('5') palette-space-level('10');
  }
}

.doughnut-chart-footer,
.result-doughnut-chart-footer {
  display: flex;
  flex-wrap: wrap;
  background-color: #f7f8fa;
  border-top: 1px solid palette-color-level('grey', '20');

  .footer-single-row {
    flex-basis: 50%;
    padding: palette-space-level('10');
  }
  .label-color {
    border-radius: palette-radius-level('0');
    width: 10px;
    height: 10px;
  }

  .single-footer-text {
    margin-left: palette-space-level('10');
  }
}
</style>
